export default [
  {
    key: "date",
    sortable: false,
    label: "Date",
  },
  {
    key: "type",
    sortable: false,
    label: "Type",
  },
  {
    key: "transaction_id",
    sortable: false,
    label: "Transaction Id",
  },
  {
    key: "amount",
    sortable: false,
    label: "Amount",
  }, {
    key: 'status',
    sortable: false,
    label: 'Status',
    visible: true,
  },
  {
    key: "method",
    sortable: false,
    label: "Method",
  },

];
