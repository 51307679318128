<template>
  <b-modal
    v-model="ownControl"

    title-class="h3 text-white font-weight-bolder"

    size="lg"
    title="UPDATE PAYMENT DATE"
    @hidden="closeModal"
  >

    <b-row class="mt-1">

      <b-col lg="6">
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
            >CLIENT
            </b-input-group-text>
          </template>
          <b-skeleton
            v-if="!finishedLoading"
            class="space"
            type="input"
          />
          <span
            v-if="finishedLoading"
            style="'border-color:rgba(255, 255, 255, 0.4)'; "
            :style="isDarkSkin ? 'background: #17171A;opacity: 0.3;color:white': 'background: #efefef'"
            class="form-control"
          >{{ client.client_name }}</span>

        </b-input-group></b-col>
      <b-col lg="6">  <b-input-group>
        <template #prepend>
          <b-input-group-text
            class="title_lable d-flex justify-content-center"
          >DATE OLD
          </b-input-group-text>
        </template>
        <b-skeleton
          v-if="!finishedLoading"
          class="space"
          type="input"
        />
        <span
          v-if="finishedLoading"
          style="'border-color:rgba(255, 255, 255, 0.4)'; "
          :style="isDarkSkin ? 'background: #17171A;opacity: 0.3;color:white': 'background: #efefef'"
          class="form-control"
        >{{ account.date | myGlobal }}</span>

      </b-input-group></b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col lg="6">  <b-input-group>
        <template #prepend>
          <b-input-group-text
            class="title_lable d-flex justify-content-center"
          >PROGRAM
          </b-input-group-text>
        </template>
        <b-skeleton
          v-if="!finishedLoading"
          class="space"
          type="input"
        />
        <span
          v-if="finishedLoading"
          style="'border-color:rgba(255, 255, 255, 0.4)'; "
          :style="isDarkSkin ? 'background: #17171A;opacity: 0.3;color:white': 'background: #efefef'"
          class="form-control w-auto"
        >{{ client.program_name }}</span>

      </b-input-group>
      </b-col>

      <b-col lg="6">
        <validation-observer ref="form">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="newDate"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  class="title_lable d-flex justify-content-center"
                >DATE NEW
                </b-input-group-text>
              </template>
              <b-skeleton
                v-if="!finishedLoading"
                class="space"
                type="input"
              />
              <kendo-datepicker
                v-if="finishedLoading"
                v-model="new_date"

                v-mask="'##/##/####'"
                :format="'MM/dd/yyyy'"
                :class="errors[0] ? 'w-auto rounded bg-transparent k-picker-custom  picker-select-date custom-caro33 border-error-datepicker' : 'w-auto rounded bg-transparent k-picker-custom  picker-select-date custom-caro33'"
              />

            </b-input-group></validation-provider>
        </validation-observer>

      </b-col>

    </b-row>

    <template #modal-footer>

      <b-button

        class="d-flex justify-content-center"
        variant="primary"
        @click="updatePaymentModal"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        UPDATE
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {

  props: {
    client: {
      type: Object,
      default: null,

    },
    transactionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      new_date: moment().format('MM/DD/YYYY'),
      finishedLoading: false,
      spinnerOn: false,
      ownControl: false,
      account: {},
    }
  },
  computed: {
    ...mapGetters({

      currentUser: 'auth/currentUser',

    }),
    roleId() {
      return this.currentUser.role_id
    },
    modulId() {
      return this.currentUser.modul_id
    },
  },
  async created() {
    this.ownControl = true
    await this.getPaymentModal()
    this.finishedLoading = true
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },

    async getPaymentModal() {
      try {
        const params = {

          transactionId: this.transactionId,
        }
        const data = await ClientsOptionsServices.getEditDayPayment(params)

        this.account = data.data[0]



        return this.account
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async updatePaymentModal() {
      try {
        const result = await this.$refs.form.validate()


        if (result) {
          const resultSwal = await this.showConfirmSwal()
          if (resultSwal.isConfirmed) {
            this.spinnerOn = true
            const params = {
              transactionId: this.transactionId,
              date: this.new_date,
              sessionId: this.currentUser.user_id,
            }

            const data = await ClientsOptionsServices.updatePaymentDay(params)

            if (data.status === 200) {
              this.$emit('refresh')
              this.showSuccessSwal()
              this.closeModal()
            }
          }
        }
      } catch (e) {
        this.spinnerOn = false
        this.showErrorSwal(e)
        return []
      }
    },
  },
}
</script>

<style scoped>
.title_lable{
  width: 100px;
}
.space{
  width: 40%;
}
.custom-caro33.k-input{
  max-width: 235px !important;
  width: 235px !important;
}

</style>
